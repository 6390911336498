import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, } from 'recharts';
import { GET_DIR } from '../../../graphql/chart';
import { client } from "../../../graphql/Client";

export default class VPEMPTY extends PureComponent {


      state = {

            activeIndex: 0,

            weeks1: 'cw',

            data: [
                  {
                        name: 'Monday', PENSION: 4000, VACANCY: 2400,
                  },
                  {
                        name: 'Tuesday', PENSION: 4000, VACANCY: 2400,
                  },
                  {
                        name: 'Wednesday', PENSION: 4000, VACANCY: 2400,
                  },
                  {
                        name: 'Thursday', PENSION: 4000,VACANCY: 2400,
                  },
                  {
                        name: 'Friday', PENSION: 4000,  VACANCY: 2400,
                  }
            ],

      };

      componentDidMount() {

            this.fetch_vps(this.state.weeks1);

      }

      componentWillReceiveProps(nextProps, stateProps) {

            console.log(nextProps);

             console.log(this.props);

            if (nextProps.week1 !== this.props.week1){

                  this.fetch_vps(nextProps.week1);

            }
           
      }
      // fecth slot

      fetch_vps = (data) => {

            client.query({

                  query: GET_DIR,

                  variables: { data: data },

                  fetchPolicy: 'no-cache',

            }).then(result => {

                  console.log(result.data.getDIR[0].data);

                  if (result.data.getDIR[0].data === null) {

                        this.setState({
                              data: [
                                    {
                                          name: 'Monday', PENSION: 0,  VACANCY: 0,
                                    },
                                    {
                                          name: 'Tuesday', PENSION: 0,VACANCY: 0,
                                    },
                                    {
                                          name: 'Wednesday', PENSION: 0,  VACANCY: 0,
                                    },
                                    {
                                          name: 'Thursday', PENSION: 0, VACANCY: 0,
                                    },
                                    {
                                          name: 'Friday', PENSION: 0, VACANCY: 0,
                                    }
                              ],
                        })

                  } else {
                        this.setState({ data: result.data.getDIR[0].data });
                  }

            });

      }
      
      static jsfiddleUrl = 'https://jsfiddle.net/alidingling/90v76x08/';

      render() {

            const {data}=this.state;
            return (
                  <BarChart width={500} height={300} data={data} style={{ left: '-3vw', bottom: '1em' }} margin={{ top: 20, right: 30, left: 20, bottom: 5, }} >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {/* <Bar dataKey="BOOKING" stackId="a" fill="#a0d911" /> */}
                        <Bar dataKey="VACANCY" stackId="a" fill="#40a9ff" />
                        <Bar dataKey="PENSION" stackId="a" fill="#f759ab" />
                  </BarChart>
            );
      }
}
