import React from "react";
import {  Icon,  Input, Button, Row, Col, Card } from 'antd';
import 'antd/dist/antd.css';
import '../../../scss/template.scss';
import '../../../scss/Dashboard.scss';
import { Alert } from '../../Layout/Files';
import Dropzone from 'react-dropzone'
import ImageCompressor from 'image-compressor.js';
import { ens } from '../../Layout/TemplateHeader';
// import { client } from "../../../graphql/Client";
import {client} from "../../../apollo";
import { ADD_ADMIN_REPORT } from '../../../graphql/Lightreport'
const InputGroup = Input.Group;
var file='';
class Add_Report_Mange extends React.Component {
    state = {
        occ_file: "", occ_imageUrl: '',
        obs_file: "", obs_imageUrl: '',
        
        leak_name: "",
        leak_no: "",
        light_name: "",
        light_no: "",
        obstacel_name: "",
        obstacel_no: "",
        occupied_name: "",
        occupied_no: "",
    };
    getBase64 = (file, no) => {
        const { occ_imageUrl, obs_imageUrl } = this.state;
        var reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onload = () => {
            if (no == 2) {
                this.setState({ obs_imageUrl: reader.result });
            } else {
                this.setState({ occ_imageUrl: reader.result });
            }
        };
        reader.onerror = (error) => {
            console.log('Error: ', error);
        };
    }
    drop = async (acceptedFiles, no) => {
        const that = this;
        console.log(no);
        await this.getBase64(acceptedFiles, no);
        await new ImageCompressor(acceptedFiles[0], {
            quality: .6,
            success(result) {
                 file = new File([result], result.name);
                console.log(that);
                if (no === 2) {
                    that.setState({obs_file : file});
                } else {
                    that.setState({occ_file:file});
                }
            }
        });
    }
    leak = async () => {
        const { leak_name, leak_no } = this.state;
        if( leak_name !=='' && leak_no!==''){
            await client.mutate({
                mutation: ADD_ADMIN_REPORT,
                variables: { admin_id: localStorage.getItem('_id'), report_category: "LEAK", file: "", slot_no: leak_no, slot_name: leak_name, lang: ens },
            }).then(result => {
                if(result.data.admin_addRepot.status==="success"){
                    this.setState({leak_name:'',leak_no:''});
                }
                Alert(result.data.admin_addRepot);
            });
        }else{
            Alert({msg:"Please enter all data",status:"failed"})
        }
    }
    light = async () => {
        const { light_name, light_no } = this.state;
        if( light_name !=='' && light_no!==''){
            await client.mutate({
                mutation: ADD_ADMIN_REPORT,
                variables: { admin_id: localStorage.getItem('_id'), report_category: "LIGHT", file: "", slot_no: light_no, slot_name: light_name, lang: ens },
            }).then(result => {
                if(result.data.admin_addRepot.status==="success"){
                    this.setState({light_name:'',light_no:''});
                }
                Alert(result.data.admin_addRepot);
               
            });
        }else{
            Alert({msg:"Please enter all data",status:"failed"})
        }
    }
    occupied = async () => {
        const { occupied_name, occupied_no,occ_file } = this.state;
        
        if( occupied_name !=='' && occupied_no!=='' && occ_file!==''){
            console.log(occ_file);
            await client.mutate({
                mutation: ADD_ADMIN_REPORT,
                variables: { admin_id: localStorage.getItem('_id'), report_category: "OCCUPIED", file:occ_file, slot_no: occupied_no, slot_name: occupied_name, lang: ens },
            }).then(result => {
                if(result.data.admin_addRepot.status==="success"){
                    this.setState({occupied_name:'',occupied_no:'',occ_file:''});
                }
                Alert(result.data.admin_addRepot);
            });
        }else{
            Alert({msg:"Please enter all data",status:"failed"})
        }
    }
    obstacel = async () => {
        const { obstacel_name,obstacel_no,obs_file } = this.state;
        if( obstacel_name !=='' && obstacel_no!=='' && obs_file!==''){
            await client.mutate({
                mutation: ADD_ADMIN_REPORT,
                variables: { admin_id: localStorage.getItem('_id'), report_category: "OBSTACLE", file: obs_file, slot_no: obstacel_no, slot_name: obstacel_name, lang: ens },
            }).then(result => {
                if(result.data.admin_addRepot.status==="success"){
                    this.setState({obstacel_name:'',obstacel_no:'',obs_file:''});
                }
                Alert(result.data.admin_addRepot);
            });
        }else{
            Alert({msg:"Please enter all data",status:"failed"})
        }
    }

    render() {
        const { occ_imageUrl, occ_file, obs_file, obs_imageUrl } = this.state;
        return (
            <div>
                <Row gutter={12} className="my-5">
                    <Col lg={12} sm={24}>
                        <Card title="OCCUPAIED REPORT" bordered={true} style={{ width: 'auto' }}>
                            <Row>
                                <div>
                                    If your booked parking space is occupied ,please take a photo of the vehicle parked(licence plate must be visible)
                                </div>
                                <div>
                                    A new parking space will be assigned once the photo is send
                                </div>
                            </Row>
                            <Row className="my-5" gutter={12}>
                                <Col lg={24}>
                                    <div className='w-25 mx-auto my-5'>
                                        <div className="gutter-box">
                                            <Dropzone onDrop={acceptedFiles => { this.drop(acceptedFiles) }}>
                                                {({ getRootProps, getInputProps, isDragActive }) => (
                                                    <section>
                                                        <div {...getRootProps()} className={occ_imageUrl === '' ? "comanfile_upload d-flex" : "d-none"}>
                                                            <input {...getInputProps()} />
                                                            {isDragActive ? (
                                                                <p>Drop the files here ...</p>
                                                            ) : (
                                                                    <Icon type="cloud-upload" className="m-auto icon" />
                                                                )}
                                                        </div>
                                                        <img src={occ_imageUrl} className={occ_imageUrl === '' ? "d-none" : ""} alt="avatar" style={{ width: '100%', height: '4.5em' }} />
                                                        <Icon type="delete" style={{ marginLeft: '4em' }} theme="twoTone" className={occ_imageUrl === '' ? "d-none" : ""} onClick={() => { this.setState({ occ_imageUrl: '' }); this.setState({ occ_file: "" }); }} />
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <InputGroup compact>
                                        <Input style={{ width: '50%' }} placeholder="slot name" onChange={(e) => { this.setState({ occupied_name: e.target.value }) }} />
                                        <Input style={{ width: '50%' }} placeholder="slot no" onChange={(e) => { this.setState({ occupied_no: e.target.value }) }} />
                                    </InputGroup>
                                </Col>
                                <Col lg={12}>
                                    <Button className="float-right" type="primary"  onClick={this.occupied}>Submit</Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col lg={12} sm={24}>
                        <Card title="OBSTACLE REPORT" bordered={true} style={{ width: 'auto' }}>
                            <Row>
                                <div>
                                    If your booked parking space is blocked by an objector obstacle ,please take a photo of the parking sapce
                                </div>
                                <div>
                                    A new parking space will be assigned once the photo is send
                                </div>
                            </Row>
                            <Row className="my-5" gutter={12}>
                                <Col lg={24}>
                                    <div className='w-25 mx-auto my-5'>
                                        <div className="gutter-box">
                                            <Dropzone onDrop={acceptedFiles => { this.drop(acceptedFiles, 2) }}>
                                                {({ getRootProps, getInputProps, isDragActive }) => (
                                                    <section>
                                                        <div {...getRootProps()} className={obs_imageUrl === '' ? "comanfile_upload d-flex" : "d-none"}>
                                                            <input {...getInputProps()} />
                                                            {isDragActive ? (
                                                                <p>Drop the files here ...</p>
                                                            ) : (
                                                                    <Icon type="cloud-upload" className="m-auto icon" />
                                                                )}
                                                        </div>
                                                        <img src={obs_imageUrl} className={obs_imageUrl === '' ? "d-none" : ""} alt="avatar" style={{ width: '100%', height: '4.5em' }} />
                                                        <Icon type="delete" style={{ marginLeft: '4em' }} theme="twoTone" className={obs_imageUrl === '' ? "d-none" : ""} onClick={() => { this.setState({ obs_imageUrl: '' }); this.setState({ obs_file: "" }); }} />
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <InputGroup compact>
                                        <Input style={{ width: '50%' }} placeholder="slot name" onChange={(e) => { this.setState({ obstacel_name: e.target.value }) }} />
                                        <Input style={{ width: '50%' }} placeholder="slot no" onChange={(e) => { this.setState({ obstacel_no: e.target.value }) }} />
                                    </InputGroup>
                                </Col>
                                <Col lg={12}>
                                    <Button className="float-right" type="primary"  onClick={this.obstacel}>Submit</Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col lg={12} sm={24}>
                        <Card title="Lighting Report" bordered={true} style={{ width: 'auto' }}>
                            <Row>
                                <div>
                                    If your booked parking space doesn`t has enough lighting or lighting is turned off, please send the report
                                </div>
                            </Row>
                            <Row className="my-5">
                                <Col lg={12}>
                                    <InputGroup compact>
                                        <Input style={{ width: '50%' }} placeholder="slot name" onChange={(e) => { this.setState({ light_name: e.target.value }) }} />
                                        <Input style={{ width: '50%' }} placeholder="slot no" onChange={(e) => { this.setState({ light_no: e.target.value }) }} />
                                    </InputGroup>
                                </Col>
                                <Col lg={12}>
                                    <Button className="float-right" type="primary" onClick={this.light}>Submit</Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col lg={12} sm={24}>
                        <Card title="Leak Report" bordered={true} style={{ width: 'auto' }}>
                            <Row>
                                <div>
                                    If your booked parking space has a leaks please send the report
                                </div>
                            </Row>
                            <Row className="my-5">
                                <Col lg={12}>
                                    <InputGroup compact>
                                        <Input style={{ width: '50%' }} placeholder="slot name" onChange={(e) => { this.setState({ leak_name: e.target.value }) }} />
                                        <Input style={{ width: '50%' }} placeholder="slot no" onChange={(e) => { this.setState({ leak_no: e.target.value }) }} />
                                    </InputGroup>
                                </Col>
                                <Col lg={12}>
                                    <Button className="float-right" type="primary"  onClick={this.leak}>Submit</Button>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div >
        );
    }
}
export default Add_Report_Mange;
