import React, { useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { strings } from '../../Layout/Strings';
import { URI, client } from "../../../graphql/Client";
import { Layout, Icon, Avatar, Badge, Spin, Form, Popconfirm, Input, Tooltip, Button, message, Typography, Row, Col, Tag, Card, Table, Modal, Upload, Select } from 'antd';
const { TextArea } = Input;
const { Option } = Select;

const GET_ARCHIVE_USER = gql`
  query getArchiveUser($page: Int,$limit: Int) {
    archiveUser(page: $page,limit: $limit) {
        totalDocs
        limit
        hasPrevPage
        hasNextPage
        page
        totalPages
        pagingCounter
        prevPage
        nextPage
        docs
    }
  }
`;
export const UserArchive = (props) => {
    const [limit, setLimit] = React.useState(10);
    const [record, setRecord] = React.useState([]);
    const { data,loading,error, fetchMore } = useQuery(
        GET_ARCHIVE_USER,
        {
            variables: { page: 1, limit: 10 },
            fetchPolicy: "cache-and-network"
        }
    );
    if (loading) return <p>Loading ...</p>;
    if (error) return `Error! ${error}`;

    const dataSource = data.archiveUser.docs || []
  
    

    const columns = [
        {
            title: <span>{strings.un}</span>,
            dataIndex: '',
            key: '',
            width: 20,
            align: 'center',
            render: (text, record) => {
                return <span title="NAME">{record.user_name}</span>;
            }
        },
        {
            title: <span>{strings.email}</span>,
            key: 'emial',
            width: 25,
            align: 'center',
            render: (text, record) => {
                return <span title="EMAIL">{record.email}</span>;
            }
        },
        {
            title: <span>{strings.ut}</span>,
            dataIndex: '',
            key: 'user_type',
            width: 10,
            align: 'center',
            render: (text, record) => {
                return <span title="USER TYPE">{record.user_type}</span>;
            }
        },
        {
            title: <span>{strings.vlp}</span>,
            dataIndex: '',
            key: 'vehicle_licenseplate',
            width: 10,
            align: 'center',
            render: (text, record) => {
                return <span title="VEHICLE NO">{record.vehicle_licenseplate}</span>;
            }
        },
        {
            title: <span>{strings.ph}</span>,
            dataIndex: 'url',
            key: 'url',
            width: 10,
            align: 'center',
            render: (text, record) => {
                return <span title="USER IMAGE"> <Avatar src={record.url} /> </span>;
            }
        },

    ];
    return (
     
        <React.Fragment>
            
            <Table expandedRowRender={record =>
                <div className="expand">
                    <Row>
                        <Col sm={24} lg={12}>
                            <Row >
                                <Col sm={12} lg={12}>
                                    <span>{strings.vc}</span>
                                </Col>
                                <Col sm={12} lg={12}>
                                    : <Tag color="blue" >{record.vehicle_color}</Tag>
                                </Col>
                            </Row>
                            <Row className="py-3">
                                <Col sm={12} lg={12}>
                                    <span>{strings.vm}</span>
                                </Col>
                                <Col sm={12} lg={12}>
                                    : <Tag color="green" >{record.vehicle_model}</Tag>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} lg={12}>
                                    <span>{strings.pw}</span>
                                </Col>
                                <Col sm={12} lg={12}>
                                    : <Tag color="green" >{record.password}</Tag>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>}
                dataSource={dataSource}
                columns={columns}
                bordered
                loading={loading}
                footer={() =>
                    <div>
                        <a href={URI + "/document/user_archive.xlsx"} download>
                            <Button type="primary" shape="round" icon="download" >
                                Download
                                </Button>
                        </a>
                    </div>
                }
                pagination={{ pageSize: 10,
                    current:data.archiveUser.page | 1,
                    total: data.archiveUser.totalDocs || 0,
                    simple: true,}}
                onChange={() => {
                    fetchMore({
                        variables: { page: data.archiveUser.nextPage, limit: 10 },
                        updateQuery: (prev, { fetchMoreResult }) => {
                            console.log(prev);
                            console.log(fetchMoreResult.archiveUser);
                            if (!fetchMoreResult) return prev;
                            // return fetchMoreResult.archiveUser;
                            return prev.archiveUser={ ...fetchMoreResult.archiveUser}
                         }
                    })}}

                        />
        </React.Fragment>
        );
    }
