import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, } from 'recharts';
import { GET_VP } from '../../../graphql/chart';
import { client } from "../../../graphql/Client";

export default class VPEMPTY extends PureComponent {


      state = {

            activeIndex: 0,

            weeks:'cw',

            data: [
                  {
                        name: 'Monday', BOOKING: 4000, CANCELED: 2400, VACANCY: 2400,
                  },
                  {
                        name: 'Tuesday', BOOKING: 4000, CANCELED: 2400, VACANCY: 2400,
                  },
                  {
                        name: 'Wednesday', BOOKING: 4000, CANCELED: 2400, VACANCY: 2400,
                  },
                  {
                        name: 'Thursday', BOOKING: 4000, CANCELED: 2400, VACANCY: 2400,
                  },
                  {
                        name: 'Friday', BOOKING: 4000, CANCELED: 2400, VACANCY: 2400,
                  }
            ],

      };

      componentDidMount() {

            this.fetch_vps(this.state.weeks);

      }

      componentWillReceiveProps(nextProps,stateProps) {
            console.log(nextProps);
            if (nextProps.week !== this.props.week) {
                  this.fetch_vps(nextProps.week);

            }
      }
      // fecth slot

      fetch_vps = (data) => {

            console.log(data);

            client.query({

                  query: GET_VP,

                  variables :{data:data},

                  fetchPolicy: 'no-cache',

            }).then(result => {

                  console.log(result.data.getVP[0].data);
                  if(result.data.getVP[0].data  === null ){ 

                        this.setState({
                               data: [
                                    {
                                          name: 'Monday', BOOKING: 0, CANCELED: 0, VACANCY: 0,
                                    },
                                    {
                                          name: 'Tuesday', BOOKING: 0, CANCELED: 0, VACANCY: 0,
                                    },
                                    {
                                          name: 'Wednesday', BOOKING: 0, CANCELED: 0, VACANCY: 0,
                                    },
                                    {
                                          name: 'Thursday', BOOKING: 0, CANCELED: 0, VACANCY: 0,
                                    },
                                    {
                                          name: 'Friday', BOOKING: 0, CANCELED: 0, VACANCY: 0,
                                    }
                              ],
                              })    

                  }else{
                    this.setState({ data: result.data.getVP[0].data });
                  }

            });

      }
      static jsfiddleUrl = 'https://jsfiddle.net/alidingling/90v76x08/';

      render() {

            const {data}=this.state;
            return (
                  <BarChart
                        width={500}
                        height={300}
                        data={data}
                        style={{ left: '-3vw', bottom: '1em'}}
                        margin={{
                              top: 20, right: 30, left: 20, bottom: 5,
                        }}
                  >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {/* <Bar dataKey="BOOKING" stackId="a" fill="#a0d911" />
                        <Bar dataKey="VACANCY" stackId="a" fill="#40a9ff" /> */}
                        <Bar dataKey="CANCELED" stackId="a" fill="#f5222d" />
                  </BarChart>
            );
      }
}
