import React, { useEffect } from "react";
import { Input, Row, Col, Select, Collapse, Form,Icon,Radio, TimePicker } from 'antd';
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Alert } from '../../Layout/Files';
import { GET_SETTINGS, UPDATE_SETTINGS } from '../../../graphql/Settings';
import { ens } from '../../Layout/TemplateHeader';
import { strings } from '../../Layout/Strings';
import moment from 'moment';
const { Option } = Select;
const { Panel } = Collapse;
export const Uploads = ((props) => {
    const [updateSettings] = useMutation(UPDATE_SETTINGS, {});
    const { data,loading,error } = useQuery(
        GET_SETTINGS,
        {
            variables: {},
            fetchPolicy: "cache-and-network"
        }
    );
    const settings = data.Settings || []

    useEffect(() => {
    }, [props]);

    const getSettings = (key) => {
        if(settings.length)
        {
            var ind = settings.findIndex(function (element) {
                return element.key == key;
            })
            if(ind!=-1)
            {
                return settings[ind].value;
            }
            else
            {
                return "";
            }
        }
    }
    const changeSetting = (key,event) => {
        console.log(event)
        const param = {
            key:key,
            value:(event.target)?event.target.value:event.toString()
        }
        update_settings(param);
    }
    const update_settings = (data) => {
        updateSettings({
            variables: {
                key: data.key,
                value: data.value,
                lang: ens
            },
        }).then(result => {
        });
    };
    const days = Array.from({ length: 31 }, (_, index) => index)

    strings.setLanguage(ens);

    return (
        (settings.length? 
        <div className="gutter-example">
            <Form layout="vertical">
                <Collapse defaultActiveKey={['1']}>
                    <Panel header="Automatic Reserve Settings" key="1">
                        <Row gutter={16}>
                            <Col className="gutter-row title_top" sm={24} lg={24}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={4}>
                                        <Form.Item label="When" >
                                            <Radio.Group onChange={(e)=>changeSetting("AUTO_RESERVE_WHEN",e)} defaultValue={getSettings("AUTO_RESERVE_WHEN")} buttonStyle="solid">
                                                <Radio.Button value="week">Weekly</Radio.Button>
                                                <Radio.Button value="month">Monthly</Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={4}>
                                        <Form.Item label="Week Days" >
                                            <Select defaultValue={getSettings("AUTO_RESERVE_WEEK")} onChange={(e)=>changeSetting("AUTO_RESERVE_WEEK",e)}>
                                                <Option value="1">Monday</Option>
                                                <Option value="2">Tuesday</Option>
                                                <Option value="3">Wednesday</Option>
                                                <Option value="4">Thursday</Option>
                                                <Option value="5">Friday</Option>
                                                <Option value="6">Saturday</Option>
                                                <Option value="7">Sunday</Option>
                                            </Select>
                                        </Form.Item>
                                                                            
                                    </Col>
                                    <Col className="gutter-row" span={4}>
                                        <Form.Item label="Day" >
                                            <Select defaultValue={getSettings("AUTO_RESERVE_DAY")} onChange={(e)=>changeSetting("AUTO_RESERVE_DAY",e)}>
                                                {days.map((item, index) => (
                                                    <Option key={index} value={index+1}>{item + 1}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                                                            
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item label="Time" >
                                            <TimePicker onChange={(e)=>changeSetting("AUTO_RESERVE_TIME",e.format('HH:mm:ss'))} defaultValue={moment(getSettings("AUTO_RESERVE_TIME"), 'HH:mm:ss')} use12Hours format="h:mm:ss A" />
                                        </Form.Item>    
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className="gutter-row alert alert-warning" span={24}>
                                        {getSettings("AUTO_RESERVE_WHEN")==="week"?
                                            (<>
                                            Cron will run every week {moment().day(getSettings("AUTO_RESERVE_WEEK")).format('dddd')} at {moment(moment().format('DD/MM/YYYY')+" "+getSettings("AUTO_RESERVE_TIME")).format("hh:mm:ss A")}
                                            </>)
                                            :
                                            (<>
                                            Cron will run every {getSettings("AUTO_RESERVE_DAY")} of month at {getSettings("AUTO_RESERVE_TIME")}
                                            </>)
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Form>
        </div>        
        :<></>)
      );
});
