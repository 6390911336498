import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { strings } from '../../Layout/Strings';
import { Row, Col, Tag, Table, Button } from 'antd';
import { URI, client } from "../../../graphql/Client";

const GET_ARCHIVE_BOOK = gql`
  query getArchiveBook($limit: Int) {
    archiveBooking(limit: $limit) {
        totalDocs
        limit
        hasPrevPage
        hasNextPage
        page
        totalPages
        pagingCounter
        prevPage
        nextPage
        docs
    }
  }
`;

const GET_ARCHIVE_BOOK_FILE = gql`
  query getArchiveBookFile {
    archiveBookingFile {
        file
    }
  }
`;
export const BookingArchive = (props) => {
    const [limit, setLimit] = React.useState(10);
    const [record, setRecord] = React.useState([]);

    // const  { loading, error, data, fetchMore } = useQuery(GET_ARCHIVE_BOOK, {
    //     variables: { page: 1, limit: 10 },
    //     fetchPolicy: "cache-and-network"
    // });

    const { loading, error, data, fetchMore } = useQuery(GET_ARCHIVE_BOOK_FILE, {
        fetchPolicy: "cache-and-network"
    });

    if (loading) return <p>Loading ...</p>;
    if (error) return `Error! ${error}`;
    // const dataSources = data.archiveBooking.docs || []
    const dataSource = data.archiveBookingFile;
    // console.log(dataSource);
    // const columns = [
    //     {
    //         title: <span>{strings.email}</span>,
    //         dataIndex: '',
    //         key: 'user_id.email',
    //         width: 20,
    //         align: 'center',
    //         render: (text, record) => {
    //             return <span title="User Name">{record.user_id === null ? '' : record.user_id.email}</span>;
    //         }
    //     },
    //     {
    //         title: <span>{strings.date}</span>,
    //         dataIndex: '',
    //         key: 'date',
    //         width: 25,
    //         align: 'center',
    //         render: (text, record) => {
    //             return <span title="Date">{record.date_formate}</span>;
    //         }
    //     },
    //     {
    //         title: <span>{strings.sln}</span>,
    //         dataIndex: '',
    //         key: 'slot_name',
    //         width: 10,
    //         align: 'center',
    //         render: (text, record) => {
    //             if (record.slot_id === null) {
    //                 return <span title="Slot_name"></span>;
    //             } else {
    //                 return <span title="Slot_name">{record.slot_id.slot_name}</span>;
    //             }
    //         }
    //     },
    //     {
    //         title: <span>{strings.slno}</span>,
    //         dataIndex: '',
    //         key: 'slots[0].slot_no',
    //         width: 10,
    //         align: 'center',
    //         render: (text, record) => {
    //             if (record.slot_id === null) {
    //                 return <span title="Slot_name"></span>;
    //             } else {
    //                 return <span title="Slot_no">{record.slot_id.slot_no}</span>;
    //             }
    //         }
    //     },
    //     {
    //         title: <span>{strings.rs}</span>,
    //         dataIndex: '',
    //         key: 'reservation',
    //         width: 10,
    //         align: 'center',
    //         className: 'p-0',
    //         render: (text, record) => {
    //             return <span title="Reservation">{record.reservation}</span>;
    //         }
    //     },
    //     {
    //         title: <span>{strings.st}</span>,
    //         dataIndex: '',
    //         key: 'status',
    //         width: 10,
    //         align: 'center',
    //         render: (text, record) => {
    //             return <span title="Status">{record.status}</span>;
    //         }
    //     },
    // ];

    return (
        <React.Fragment>
            {/* <Table expandedRowRender={record =>
                <div className="expand">
                    <Row>
                        <Col sm={24} lg={12}>
                            <Row >
                                <Col sm={12} lg={12}>
                                    <span>{strings.vc}</span>
                                </Col>
                                <Col sm={12} lg={12}>
                                    : <Tag color="blue" >{record.vehicle_color}</Tag>
                                </Col>
                            </Row>
                            <Row className="py-3">
                                <Col sm={12} lg={12}>
                                    <span>{strings.vm}</span>
                                </Col>
                                <Col sm={12} lg={12}>
                                    : <Tag color="green" >{record.vehicle_model}</Tag>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} lg={12}>
                                    <span>{strings.pw}</span>
                                </Col>
                                <Col sm={12} lg={12}>
                                    : <Tag color="green" >{record.password}</Tag>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>}
                dataSource={dataSources}
                columns={columns}
                bordered
                loading={loading}
                footer={() =>
                    <div>
                        <a href={URI + "/document/booking_archive.xlsx"} download>
                            <Button type="primary" shape="round" icon="download" >
                                Download
                                </Button>
                        </a>
                    </div>
                }
                pagination={{
                    pageSize: 10,
                    current: data.archiveBooking.page | 1,
                    total: data.archiveBooking.totalDocs || 0,
                    simple: true,
                }}
                onChange={() => {
                    fetchMore({
                        variables: { page: data.archiveBooking.nextPage, limit: 10 },
                        updateQuery: (prev, { fetchMoreResult }) => {
                            console.log(prev);
                            console.log(fetchMoreResult.archiveBooking);
                            if (!fetchMoreResult) return prev;
                            // return fetchMoreResult.archiveUser;
                            return prev.archiveBooking = { ...fetchMoreResult.archiveBooking }
                        }
                    })
                }}
            /> */}
            <div>
                {dataSource[0]?dataSource[0].file.length>0?
                <>
                    {dataSource[0].file.map((data, i) => (
                        <div className="d-flex">
                            <div className="m-3" style={{
                                fontSize: '2em',
                                border: '1px solid',
                                padding: '4px',
                                borderRadius: '1em',
                            }}>{data}</div>
                            <a className="my-3" href={URI + `/document/${data}`} download>
                                <Button type="primary" shape="round" icon="download" >
                                    Download
                                    </Button>
                            </a>
                        </div>
                    ))}</>
                    :<></>:<></>}
            </div>
        </React.Fragment >
    );
}
