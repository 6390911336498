import React from "react";
import { Icon,  } from 'antd';
import 'antd/dist/antd.css';
import { Menu } from 'antd';
import moment from "moment";
import 'moment/locale/es-us';
import '../../../scss/template.scss';
import '../../../scss/Dashboard.scss';
import '../../../scss/User.scss';
import gql from "graphql-tag";
import { URI, client } from "../../../graphql/Client";

import { UserArchive } from "./userArchive";
import { BookingArchive } from './bookingArchive'
import { ReportArchive } from './reportArchive'
import { PenaltyArchive } from './penaltyArchive'

moment.locale('es');


export const ARCHIVE_HISTORY = gql`
  query ARCHIVEHISTORY{
      archive_history{ 
      msg
    }
  }
`;

class Archive_Manage extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  current: 'user',
            };
      }
      componentDidMount(){
          this.download();
      }
      download = async () => {
            await client.query({
                  query: ARCHIVE_HISTORY,
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  // Alert(result.data.deletePenalty);
            });
      }
      
      render() {

            return (
                  <div>
                        <Menu onClick={(e) => {
                              this.setState({
                                    current: e.key,
                              });
                        }} selectedKeys={[this.state.current]} mode="horizontal">
                              <Menu.Item key="user">
                                    <Icon type="user" />
                                    USER HISTORY
                               </Menu.Item>
                              <Menu.Item key="book" >
                                    <Icon type="appstore" />
                                    BOOKING HISTORY
                              </Menu.Item>
                              <Menu.Item key="report">
                              <Icon type="bell" />
                                    REPORT HISTORY
                              </Menu.Item>
                              <Menu.Item key="penalty">
                              <Icon type="alert" />
                                    PENALTY HISTORY
                              </Menu.Item>
                        </Menu>
                        <div className={this.state.current === 'user' ? 'mt-2' : 'd-none'}>
                              <UserArchive />
                        </div>
                        <div className={this.state.current === 'book' ? 'mt-2' : 'd-none'}>
                              <BookingArchive />
                        </div>
                        <div className={this.state.current === 'report' ? 'mt-2' : 'd-none'}>
                              <ReportArchive />
                        </div>
                        <div className={this.state.current === 'penalty' ? 'mt-2' : 'd-none'}>
                              <PenaltyArchive />
                        </div>
                  </div>
            );
      }
}
export default Archive_Manage;
