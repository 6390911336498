import { message, Button } from 'antd';

export const Alert = (data) => {

  console.log(data);

  if (data.status === 'success') {

    return (message.success(data.msg, [1]));

  } else {

    return (message.error(data.msg,[1]));
    
  }

};