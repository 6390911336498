import gql from "graphql-tag";
export const GET_USER = gql` 
query Getbooking($limit: Int!, $page: Int) {
    users(limit: $limit, page: $page) {
            _id,
            user_name,
            sure_name,
            photo,
            email,
            vehicle_model,
            vehicle_color,
            vehicle_licenseplate,
            user_type,
            file,
            url,
            msg,
            password,
            slot_id,
            totalDocs,
            totalPages,
            docs,
            page,
            employeenumber,
            cardnumber,
            cardfamily
      }
}
`;

export const FIND_USER = gql`
      query finduser($_id: ID,$lang:String) {
            user(_id: $_id,lang:$lang) {
                  _id,
                  user_name,
                  sure_name,
                  photo,
                  email,
                  vehicle_model,
                  vehicle_color,
                  vehicle_licenseplate,
                  user_type,
                  file,
                  url,
                  msg,
                  slot_id,
                  start_date,
                  end_date,
                  password,
                  employeenumber,
                  cardnumber,
                  cardfamily

            }
      }
`;


export const DELETE_USER = gql`
    mutation DELETEUSER($_id: ID,$lang:String)  {
      deleteUser(_id:$_id,lang:$lang){
        msg,
        status         
    }
}`;

export const DOWNLOAD_USER = gql`
{
    downloaduser{ 

      _id
      
    }
  }
`;



export const SEARCH_USER = gql`
      query findusers($data: JSON,$lang:String) {
            search_user(data: $data,lang:$lang) {
                  _id,
                  user_name,
                  sure_name,
                  photo,
                  email,
                  vehicle_model,
                  vehicle_color,
                  vehicle_licenseplate,
                  user_type,
                  file,
                  url,
                  msg,
                  slot_id,
                  password,

            }
      }
`;


export const USER_EMAIL_QUERY = gql`
      query findusers($data: JSON,$lang:String) {
            search_user_email(data: $data,lang:$lang) {
                  _id,
                  user_name,
                  sure_name,
                  photo,
                  email,
                  vehicle_model,
                  vehicle_color,
                  vehicle_licenseplate,
                  user_type,
                  file,
                  url,
                  msg,
                  slot_id,
                  password,

            }
      }
`;
