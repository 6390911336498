import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { strings } from '../../Layout/Strings';
import { Table,Button } from 'antd';
import { URI, client } from "../../../graphql/Client";

const GET_ARCHIVE_PENALTY = gql`
  query getArchivePenalty($limit: Int) {
    archivePenalty(limit: $limit) {
        _id
      penality_level,
      penality_userID,
      startdate,
      enddate,
      startdate_formate,
      enddate_formate,
      pension_status,
      slot_name,
      counts,
    }
  }
`;
export const PenaltyArchive = (props) => {
    const [limit, setLimit] = React.useState(10);
    const [record, setRecord] = React.useState([]);

    const { loading, error, data, fetchMore } = useQuery(GET_ARCHIVE_PENALTY, {
        fetchPolicy: "no-cache"
    });
    if (loading) return <p>Loading ...</p>;
    if (error) return `Error! ${error}`;

    const dataSource = data.archivePenalty || []
    console.log(dataSource);
    const columns = [
        {
            title: <span>{strings.un}</span>,
            dataIndex: '',
            key: 'penality_userID.user_name',
            width: 20,
            align: 'center',
            render: (text, record) => {
                return <span title="USER NAME">{record.penality_userID.user_name}</span>;
            }
        },
        {
            title: <span>{strings.pl}</span>,
            dataIndex: '',
            key: 'penality_level',
            width: 10,
            align: 'center',
            render: (text, record) => {
                return <span title="PENALTY LEVEL">{record.penality_level}</span>;
            }
        },
        {
            title: <span>{strings.pc}</span>,
            dataIndex: '',
            key: 'count',
            width: 10,
            align: 'center',
            render: (text, record) => {
                return <span title="PENALTY COUNT">{record.counts}</span>;
            }
        },
        {
            title: <span>{strings.psd}</span>,
            dataIndex: '',
            key: 'startdate_formate',
            width: 20,
            align: 'center',
            render: (text, record) => {
                return <span title="PENSION START DATE">{record.startdate_formate}</span>;
            }
        },
        {
            title: <span>{strings.ped}</span>,
            dataIndex: '',
            key: 'enddate_formate',
            width: 20,
            align: 'center',
            render: (text, record) => {
                return <span title="PENSION END DATE">{record.enddate_formate}</span>;
            }

        },
        {
            title: <span>{strings.st}</span>,
            dataIndex: '',
            key: 'pension_status',
            width: 10,
            align: 'center',
            render: (text, record) => {
                return <span title="STATUS">{record.pension_status}</span>;
            }
        },
    ];

    return (
        <React.Fragment>
            <Table
                dataSource={dataSource}
                columns={columns}
                bordered
                loading={loading}
                pagination={{ simple: true }}
                footer={() =>
                    <div>
                        <a href={URI + "/document/penalty_archive.xlsx"} download>
                            <Button type="primary" shape="round" icon="download" >
                                Download
                                </Button>
                        </a>
                    </div>
                }
            />
        </React.Fragment>
    );
}
