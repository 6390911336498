// apollo schema:download --endpoint=http://192.168.0.21:8888/graphql schema.json
import ApolloClient from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-boost";
import { createUploadLink } from "apollo-upload-client";
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { URI, W_URI } from '../src/graphql/Client';

const links = createUploadLink({ uri: `${URI}/graphql`, });

const wsLink = new WebSocketLink({
  uri: W_URI,
  options: {
    reconnect: true,
  }
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  links
);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});
