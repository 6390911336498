import React, { useCallback, Component, Fragment } from "react";
import ReactDOM from 'react-dom';
import Parser from 'html-react-parser';
import ReactDOMServer from 'react-dom/server';
import { BrowserRouter as Router, Route, Link, withRouter } from 'react-router-dom';
import { Layout, Icon, Avatar, Form, Input, Tooltip, Button, message, Typography, Row, Col, Card, Popconfirm, Tag, Table, Modal, Upload, Select } from 'antd';
import 'antd/dist/antd.css';
import '../../../scss/template.scss';
import '../../../scss/Dashboard.scss';
import '../../../scss/slot.scss';
import {Alert} from '../../Layout/Files'
import {GET_STATIC,DELETE_STATIC,FIND_STATIC} from '../../../graphql/Static';
import { client } from "../../../graphql/Client";
import { ens, TemplateHeader } from '../../Layout/TemplateHeader';
import {strings} from '../../Layout/Strings';

const { Content } = Layout;
const { Text, Paragraph } = Typography;
const { TextArea } = Input;
const { Option } = Select;


class Static_Manage extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  _id:'',
                  title: '',
                  description: '',
                  conten:'',
                  file: '',
                  other:'',
                  imageUrl: '',
                  dataSource: [],
                  UpdateStatic:0,
                  data:[],
                  loading: 1,

            };
      }

      componentDidMount() {

            this.fetch_Static();

      }

      // fecth Static
      fetch_Static = () => {

            client.query({

                  query: GET_STATIC,

                  fetchPolicy: 'no-cache',

            }).then(result => {


                  if (result.data.Static[0].msg === 'NO STATIC DATA')
                  {

                        this.setState({ loading: false, dataSource: []});


                  }else{

                        this.setState({ loading: false, dataSource: result.data.Static });

                  }

            });
      }
      staticDelete = async (_id)=>{

            console.log(ens);

            await client.mutate({

                  mutation: DELETE_STATIC,

                  variables: { _id: _id , lang : ens },

            }).then(result => {

                 Alert(result.data.deleteStatic);

            });

            this.fetch_Static();
      }

        // user data visible in update process
        staticEdit = async (_id) => {

            console.log(_id);

            await client.query({

                  query: FIND_STATIC,

                  variables: { _id: _id, lang: ens },

                  fetchPolicy: 'no-cache',

            }).then(result => {

                  this.props.history.push('./admin-add-static-content',{data:result.data.Static});

            });
      }

      render() {

            strings.setLanguage(ens);
            
            const columns = [
                  {
                        title:<span>{strings.ti}</span>,
                        dataIndex: '',
                        key: 'title',
                        width: 20,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="TITLE">{record.title}</span>;
                        }
                  },
                  {
                        title: <span>{strings.ds}</span>,
                        dataIndex: '',
                        key: 'description',
                        width: 25,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="DESCRIPTION">{record.description}</span>;
                        }
                  },
                  {
                        title: '...',
                        dataIndex: '...',
                        width: 5,
                        align: 'center',
                        render: (text, record) => {
                              if (this.state.dataSource.length >= 1) {
                                    return (
                                          <div>

                                                <Icon type="edit" tabIndex="-1" onClick={() => {this.staticEdit(record._id)}} />

                                                <Popconfirm title="Sure to delete?" onConfirm={() => this.staticDelete(record._id)}>
                                                      <Icon type="delete" />
                                                </Popconfirm>
                                          </div>
                                    );
                              }
                        }
                  },
            ];
            return (
                  <div>

                        <div className="user_add_position">
                            
                              <Tooltip placement="left" title={strings.adsc}>
                                  
                                    <Icon type="plus-circle" className="user_add icon_size" onClick={()=>{this.props.history.push('./admin-add-static-content')}}/>
                            
                              </Tooltip>

                        </div>

                        <div id="no-more-tables">

                        <Table expandedRowRender={record =>

                              <div className="d-block">
                                    
                                          <div> <span>{strings.ct}</span> </div>
                                  
                                    <Paragraph>
                                       
                                          {record.content === null ? 'NO DATA' : <div>{Parser(record.content)}</div> }
                                  
                                    </Paragraph>
                                  
                                    <div> Others ...</div>
                                   
                                    <Paragraph>
                                       
                                          {record.others === null ? 'NO DATA' : record.others}
                                   
                                    </Paragraph>
                             
                              </div>}

                              dataSource={this.state.dataSource}

                              columns={columns}

                              loading={this.state.loading}

                              bordered

                              pagination={{ simple: true }}

                        />
                        </div>
                  </div>
            );
      }
}

export default withRouter(Static_Manage);
