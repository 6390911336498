import React, { useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";
import { URI, client } from "../../../graphql/Client";
import gql from "graphql-tag";
import { strings } from '../../Layout/Strings';
import { Layout, Icon, Avatar, Badge, Spin, Form, Popconfirm, Input, Tooltip, Button, message, Typography, Row, Col, Tag, Card, Table, Modal, Upload, Select } from 'antd';

const GET_ARCHIVE_REPORT = gql`
  query getArchiveReport($limit: Int) {
    archiveReport(limit: $limit) {
                  _id
                  report_userID,
                  booking_status,
                  slot_id,
                  role_id,
                  photo,
                  report_category,
                  report_date,
                  reservation,
                  date_formate,
                  url,
                  status,
                  msg,
                  admin_report{
                        email,
                        user_name
                  },
     
    }
  }
`;
export const ReportArchive = (props) => {
      const [limit, setLimit] = React.useState(10);
      const [record, setRecord] = React.useState([]);

      const { loading, error, data, fetchMore } = useQuery(GET_ARCHIVE_REPORT, {
            fetchPolicy: "no-cache"
      });
      if (loading) return <p>Loading ...</p>;
      if (error) return `Error! ${error}`;

      const dataSource = data.archiveReport || []
      console.log(dataSource);
      const columns = [
            {
                  title: <span>{strings.un}</span>,
                  dataIndex: '',
                  key: 'report_userID.user_name',
                  width: 20,
                  align: 'center',
                  render: (text, record) => {
                        if (record.role_id !== '' && record.role_id !== undefined && record.role_id !== null) {
                              return <span title="USER NAME"><Tag color="purple">{record.admin_report.user_name}</Tag></span>;
                        } else {
                              return <span title="USER NAME">{record.report_userID.user_name}</span>;
                        }
                  }
            },
            {
                  title: <span>{strings.rcat}</span>,
                  dataIndex: '',
                  key: 'report_category',
                  width: 20,
                  align: 'center',
                  render: (text, record) => {
                        return <span title="REPORT CATEGORY">{record.report_category}</span>;
                  }
            },
            {
                  title: <span className="p-5">{strings.date}</span>,
                  dataIndex: '',
                  key: 'date_formate',
                  width: 30,
                  align: 'center',
                  render: (text, record) => {
                        return <span title="DATE">{record.date_formate}</span>;
                  }
            },
            {
                  title: <span>{strings.book_rs}</span>,
                  dataIndex: '',
                  key: 'date_formate',
                  width: 10,
                  align: 'center',
                  render: (text, record) => {
                        if (record.booking_status === null) {
                              return <span title="BOOKING RESERVATION">{'DELETED BOOKING'}</span>;
                        } else {
                              return <span title="BOOKING RESERVATION">{record.booking_status.reservation}</span>;
                        }
                  }
            },
            {
                  title: <span>{strings.rep_rs}</span>,
                  dataIndex: '',
                  key: 'date_formate',
                  width: 10,
                  align: 'center',
                  render: (text, record) => {
                        return <span title="DATE">{record.reservation}</span>;
                  }
            },
            {
                  title: <span>{strings.bs}</span>,
                  dataIndex: '',
                  key: 'booking_status.status',
                  width: 10,
                  align: 'center',
                  render: (text, record) => {
                        if (record.booking_status === null) {
                              return <span title="BOOKING STATUS">{'finished'}</span>;
                        } else {
                              return <span title="BOOKING STATUS">{record.booking_status.status}</span>;
                        }
                  }
            },
            {
                  title: <span>{strings.rst}</span>,
                  dataIndex: '',
                  key: 'status',
                  width: 10,
                  align: 'center',
                  render: (text, record) => {
                        return <span title="REPORT STATUS">{record.status}</span>;
                  }
            },
      ];
      return (
            <React.Fragment>
                  <Table
                        dataSource={dataSource}
                        columns={columns}
                        bordered
                        loading={loading}
                        pagination={{ simple: true }}
                        footer={() =>
                              <div>
                                    <a href={URI + "/document/report_archive.xlsx"} download>
                                          <Button type="primary" shape="round" icon="download" >
                                                Download
                              </Button>
                                    </a>
                              </div>
                        }
                  />
            </React.Fragment>
      );
}
