import React, { useCallback } from "react";
import { Layout, Icon, Avatar, Form, Input, Tooltip, Button, message, Popconfirm, Tag, Typography, Row, Col, Card, Table, Modal } from 'antd';
import 'antd/dist/antd.css';
import '../../../scss/template.scss';
import '../../../scss/Dashboard.scss';
import { Alert } from '../../Layout/Files';
import { BrowserRouter as Router, Route, Link, withRouter } from 'react-router-dom';
import { GET_REPORT, DELETE_REPORT, DOWNLOAD_REPORT, SEARCH_REP } from '../../../graphql/Report';
import { client, URI } from "../../../graphql/Client";
import { finished } from "stream";
import { TemplateHeader, ens } from '../../Layout/TemplateHeader';
import { strings } from '../../Layout/Strings';
const { Content } = Layout;
const { Text } = Typography;
const { Search } = Input;
class Report_Mange extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  reportVisible: false,
                  dataSource: [],
                  loading: 1,
            };
      }
      componentDidMount() {
            this.fetch_Report();
      }
      // fecth user
      fetch_Report = (visible) => {
            this.download();
            client.query({
                  query: GET_REPORT,
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  console.log(result.data.report);
                  this.setState({ loading: false, dataSource: result.data.report });
            });
      }
      download = async () => {
            await client.query({
                  query: DOWNLOAD_REPORT,
            }).then(result => {
                  // Alert(result.data.deletePenalty);
            });
      }
      // report visible
      reportVisible = (_id) => {
            console.log(_id);
            this.props.history.push({ pathname: '/admin-reportView', search: '', state: { _id: _id } });
      }
      // delete a record
      reportDelete = async (_id) => {
            console.log(_id);
            await client.mutate({
                  mutation: DELETE_REPORT,
                  variables: { _id: _id, lang: ens },
            }).then(result => {
                  Alert(result.data.deleteReport);
            });
            this.fetch_Report();
      };
      res_search = value => {
            client.query({
                  query: SEARCH_REP,
                  variables: { lang: ens, data: value },
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  console.log(result.data);
                  if (result.data.search_rep.length != 0) {
                        this.setState({ loading: false, dataSource: result.data.search_rep });
                  } else {
                        Alert({ msg: "INVALID DATA", status: 'failed' });
                  }
            });
      }
      render() {
            strings.setLanguage(ens);
            const columns = [
                  {
                        title: <span>{strings.un}</span>,
                        dataIndex: '',
                        key: 'report_userID.user_name',
                        width: 20,
                        align: 'center',
                        render: (text, record) => {
                              if (record.role_id !== '' && record.role_id !== undefined && record.role_id !== null ) {
                                    return <span title="USER NAME"><Tag color="purple">{record.admin_report.user_name}</Tag></span>;
                              } else {
                                    return <span title="USER NAME">{record.report_userID.user_name}</span>;
                              }
                        }
                  },
                  {
                        title: <span>{strings.rcat}</span>,
                        dataIndex: '',
                        key: 'report_category',
                        width: 20,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="REPORT CATEGORY">{record.report_category}</span>;
                        }
                  },
                  {
                        title: <span className="p-5">{strings.date}</span>,
                        dataIndex: '',
                        key: 'date_formate',
                        width: 30,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="DATE">{record.date_formate}</span>;
                        }
                  },
                  {
                        title: <span>{strings.book_rs}</span>,
                        dataIndex: '',
                        key: 'date_formate',
                        width: 10,
                        align: 'center',
                        render: (text, record) => {
                              if (record.booking_status === null) {
                                    return <span title="BOOKING RESERVATION">{'DELETED BOOKING'}</span>;
                              } else {
                                    return <span title="BOOKING RESERVATION">{record.booking_status.reservation}</span>;
                              }
                        }
                  },
                  {
                        title: <span>{strings.rep_rs}</span>,
                        dataIndex: '',
                        key: 'date_formate',
                        width: 10,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="DATE">{record.reservation}</span>;
                        }
                  },
                  {
                        title: <span>{strings.bs}</span>,
                        dataIndex: '',
                        key: 'booking_status.status',
                        width: 10,
                        align: 'center',
                        render: (text, record) => {
                              if (record.booking_status === null) {
                                    return <span title="BOOKING STATUS">{'finished'}</span>;
                              } else {
                                    return <span title="BOOKING STATUS">{record.booking_status.status}</span>;
                              }
                        }
                  },
                  {
                        title: <span>{strings.rst}</span>,
                        dataIndex: '',
                        key: 'status',
                        width: 10,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="REPORT STATUS">{record.status}</span>;
                        }
                  },
                  {
                        title: '...',
                        dataIndex: '...',
                        width: 5,
                        align: 'center',
                        render: (text, record) => {
                              if (this.state.dataSource.length >= 1) {
                                    return (
                                          <div>
                                                <Icon type="eye" tabIndex="-1" onClick={() => this.reportVisible(record._id)} />
                                                <Popconfirm title="Sure to delete?" onConfirm={() => this.reportDelete(record._id)}>
                                                      <Icon type="delete" />
                                                </Popconfirm>
                                          </div>
                                    );
                              }
                        }
                  },
            ];
            return (
                  <div>
                        <Row>
                              <Col className='float-right d-flex' style={{ top: '-3vh' }} xl={12} sm={24}>
                                    <Search placeholder={strings.rs} enterButton="Search" size="small" onSearch={(value) => { this.res_search(value) }} />
                                    <Button className="ml-4" type="dashed" size="small" onClick={this.fetch_Report}>ALL REPORTS</Button>
                                    <Button className="ml-4" type="dashed" size="small" onClick={() => { this.props.history.push('/admin-add-report') }} >Add REPORTS</Button>
                                    <Button className="ml-4" type="dashed" size="small" onClick={() => { this.props.history.push('/admin-add-penalty') }} >Add Penality</Button>
                              </Col>
                        </Row>
                        <div id="no-more-tables">
                              <Table
                                    pagination={{ simple: true }}
                                    dataSource={this.state.dataSource}
                                    columns={columns}
                                    bordered
                                    loading={this.state.loading}
                                    footer={() =>
                                          <a href={URI + "/document/report.xlsx"} download>
                                                <Button type="primary" shape="round" icon="download" >
                                                      Download
                                          </Button>
                                          </a>}
                              />
                        </div>
                  </div>
            );
      }
}
export default withRouter(Report_Mange);
