import ApolloClient from 'apollo-boost';
const { createUploadLink } = require('apollo-upload-client')
// export const URI = 'http://tryout.waioz.com:8008';
// export const URI = 'https://tryout.waioz.com:8008';
// export const URI = 'http://192.168.0.33:8888';
// export const URI = 'http://localhost:8008';
export const URI = 'https://essity.gravipark.com';    

//------------------ scoket url:--------------------//
// export const W_URI = `ws://localhost:8008/graphql`;
// export const W_URI =`ws://192.168.0.33:8888/graphql`;
// export const W_URI =`ws://tryout.waioz.com:8008/graphql`;
// export const W_URI =`wss://tryout.waioz.com:8008/graphql`;
export const W_URI = `wss://essity.gravipark.com/graphql`;  


export const client = new ApolloClient({
      uri: `${URI}/graphql`,
});
