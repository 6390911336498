import gql from "graphql-tag";
export const GET_PENALTYDETAIL = gql`
{
      panality_detail
      {
            _id
            penality_level,
            duration,
            cause,
      }
}
`;

export const FIND_PENALTY = gql`
    query FINDPENALTY($_id: ID,$lang:String) {
      panality_detail(_id:$_id,lang:$lang)
      {
            _id
            penality_level,
            duration,
            cause,
      }
    }
`;

export const ADD_PENALTYDETAIL = gql`
    mutation ADDPENALTYDETAIL($penality_level:Int,$duration:Int,$cause: String,$lang:String) {   
      addPenaltyDetail(penality_level:$penality_level, duration:$duration, cause: $cause,lang:$lang)
        {
            msg,
            status,
        }
    }
`;
export const DELETE_PENALTY = gql`
    mutation DELETEPENALTY($_id: ID,$lang:String)  {
      deletePenaltyDetail(_id:$_id,lang:$lang){
        msg,
        status         
    }
}`;

export const UPDATE_PENALTYDETAIL = gql`
  mutation UPDATEPENALTYDETAIL($_id:ID,$penality_level:Int,$duration:Int,$cause: String,$lang:String) {   
      updatePenaltyDetail(_id:$_id,penality_level:$penality_level, duration:$duration, cause: $cause,lang:$lang)
        {
            msg,
            status
        }
    }
`;


