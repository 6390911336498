import gql from "graphql-tag";
export const GET_ADMIN = gql`
{
      admins
      {
            _id,
            user_name,
            sure_name,
            photo,
            email,
            user_type,
            url,
            msg,
            password,
      }
}
`;

export const FIND_ADMIN = gql`
      query findadmin($_id: ID,$lang:String) {
            admin_s(_id: $_id,lang:$lang) {
                  _id,
                  user_name,
                  sure_name,
                  photo,
                  email,
                  user_type,
                  url,
                  msg,
                  password,

            }
      }
`;


export const DELETE_ADMIN = gql`
    mutation DELETEADMIN($_id: ID,$lang:String)  {
      deleteAdmin(_id:$_id,lang:$lang){
        msg,
        status         
    }
}`;

export const DOWNLOAD_USER = gql`
{
    downloaduser{ 

      _id
      
    }
  }
`;



export const SEARCH_ADMIN = gql`
      query findadminss($data: String,$lang:String) {
            search_admin(data: $data,lang:$lang) {
                  _id,
                  user_name,
                  sure_name,
                  photo,
                  email,
                  user_type,
                  url,
                  msg,
                  password,

            }
      }
`;


