import React, { useCallback } from "react";
import { Query } from "react-apollo";
import { useDropzone } from "react-dropzone";
import gql from "graphql-tag";
import {  Icon,LocaleProvider, Spin,  Input,DatePicker, Button, Row, Col,  Select } from 'antd';
import { useMutation } from "@apollo/react-hooks";
import fr_FR from 'antd/es/locale-provider/en_US';
import { Alert } from '../../Layout/Files';
import { FIND_VP_SLOT } from '../../../graphql/slot';
import { ens } from '../../Layout/TemplateHeader';
import { strings } from '../../Layout/Strings';
import ImageCompressor from 'image-compressor.js';
const { Option } = Select;
const { RangePicker } = DatePicker;

const ADD_USER = gql`
    mutation ADDUSER($user_name: String,$sure_name: String, $photo: String,$file:[Upload],$end_date:String,$start_date:String,
      $email: String,$vehicle_model: String,$vehicle_color: String,$vehicle_licenseplate: String,$user_type: String,$slot_id:ID,$password:String,$lang:String,$cardnumber:String,
      $cardfamily:String,
      $employeenumber:String) {   
      addUser(user_name: $user_name,sure_name: $sure_name, photo: $photo,file:$file,email: $email,vehicle_model: $vehicle_model,
      vehicle_color: $vehicle_color,vehicle_licenseplate: $vehicle_licenseplate,user_type: $user_type,slot_id:$slot_id,password:$password,lang:$lang,end_date:$end_date,start_date:$start_date,
      cardnumber:$cardnumber,
            cardfamily:$cardfamily,
            employeenumber:$employeenumber)
        {
            _id,
            user_name,
            sure_name,
            photo,
            email,
            vehicle_model,
            vehicle_color,
            vehicle_licenseplate,
            user_type,
            file,
            url,
            msg,
            status,
            start_date,
            end_date
        }
    }
`;
export const Uploads = (props) => {
      const [user_name, setuser_name] = React.useState('');
      const [sure_name, setsure_name] = React.useState('');
      const [email, setemail] = React.useState('');
      const [vehicle_color, setvehicle_color] = React.useState('');
      const [vehicle_licenseplate, setvehicle_licenseplate] = React.useState('');
      const [vehicle_model, setvehicle_model] = React.useState('');
      const [user_type, setuser_type] = React.useState('');
      const [password, setpassword] = React.useState('');
      const [cardfamily, setcardfamily] = React.useState('');
      const [cardnumber, setcardnumber] = React.useState('');
      const [employeenumber, setemployeenumber] = React.useState('');
      const [slot_id, setslot_id] = React.useState('');
      const [start_date, setstart_date] = React.useState('');
      const [end_date, setend_date] = React.useState('');
      const [file, setFile] = React.useState('');
      const [imageUrl, setimageUrl] = React.useState('');
      const [uploadFile] = useMutation(ADD_USER, {});
      const [spin, setspin] = React.useState(0);
      const getBase64 = (file) => {
            var reader = new FileReader();
            reader.readAsDataURL(file[0]);
            reader.onload = () => {
                  console.log(reader.result);
                  setimageUrl(reader.result);
            };
            reader.onerror = (error) => {
                  console.log('Error: ', error);
            };
      }
      const onDrop = async acceptedFiles => {
            console.log(acceptedFiles);
            await getBase64(acceptedFiles);
            await new ImageCompressor(acceptedFiles[0], {
                  quality: .6,
                  success(result) {
                        console.log(result);
                        var file = new File([result], result.name);
                        console.log(file);
                        setFile(file);
                  }
            });
            // setFile(acceptedFiles);
      }
      const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: 0 });
      const onChange0 = event => setuser_name(event.target.value);
      const onChange1 = event => setsure_name(event.target.value);
      const onChange2 = event => setemail(event.target.value);
      const onChange3 = event => setvehicle_model(event.target.value);
      const onChange4 = event => setvehicle_color(event.target.value);
      const onChange5 = event => setvehicle_licenseplate(event.target.value);
      const onChange6 = value => setuser_type(value);
      const onChange7 = event => setpassword(event.target.value);
      const onChange8 = event => setemployeenumber(event.target.value);
      const onChange9 = event => setcardfamily(event.target.value);
      const onChange10 = event => setcardnumber(event.target.value);
      const onSlot = value => { setslot_id(value) };
      const oncancel = () => { props.cancelmodel(false); }
      const AddUser = useCallback(
            (event) => {
                  console.log(slot_id);
                  setspin(1);
                  if (user_name !== '' && email !== '' && vehicle_licenseplate !== '' && user_type !== '') {
                        if (user_type === 'VP' || user_type === 'DIRECTOR') {
                              if (slot_id === '') {
                                    setspin(0);
                                    Alert({ msg: 'PLEASE CHOOSE USER SLOT', status: 'failed' });
                              } else {
                                    // this.props.
                                    uploadFile({
                                          variables: {
                                                user_name: user_name,
                                                sure_name: sure_name,
                                                email: email,
                                                vehicle_color: vehicle_color,
                                                vehicle_licenseplate: vehicle_licenseplate,
                                                vehicle_model: vehicle_model,
                                                user_type: user_type,
                                                file: file,
                                                slot_id: slot_id,
                                                password: password,
                                                cardfamily: cardfamily,
                                                cardnumber: cardnumber,
                                                employeenumber: employeenumber,
                                                start_date:start_date,
                                                end_date:end_date,
                                                lang: ens,
                                          },
                                    }).then(result => {
                                          setspin(0);
                                          console.log(result.data);
                                          props.adduser(result.data);
                                    });
                              }
                        } else {
                              uploadFile({
                                    variables: {
                                          user_name: user_name,
                                          sure_name: sure_name,
                                          email: email,
                                          vehicle_color: vehicle_color,
                                          vehicle_licenseplate: vehicle_licenseplate,
                                          vehicle_model: vehicle_model,
                                          user_type: user_type,
                                          file: file,
                                          slot_id: slot_id,
                                          password: password,
                                          cardfamily: cardfamily,
                                          cardnumber: cardnumber,
                                          employeenumber: employeenumber,
                                          start_date:start_date,
                                          end_date:end_date,
                                          lang: ens,
                                    },
                              }).then(result => {
                                    setspin(0);
                                    console.log(result.data);
                                    props.adduser(result.data);
                              });
                        }
                  } else {
                        setspin(0);
                        Alert({ msg: 'PLEASE ADD MANDATORY FIELD', status: 'failed' });
                  }
            },
            [uploadFile]
      );
      strings.setLanguage(ens);
      return (
            <div className="gutter-example">
                  <Spin tip="Loading..." className={spin === 0 ? 'd-none' : ''}  > </Spin>
                  <Row gutter={16}>
                        <Col className="gutter-row" span={18}>
                              <Row gutter={16}>
                                    <Col className="gutter-row" span={16}>
                                          <div className='d-flex' style={{ marginBottom: 16 }}>
                                                <Input placeholder={strings.un} value={user_name} onChange={onChange0} />
                                          </div>
                                    </Col>
                                    <Col className="gutter-row" span={8}>
                                          <div style={{ marginBottom: 16 }}>
                                                <Input placeholder={strings.sn} value={sure_name} onChange={onChange1} />
                                          </div>
                                    </Col>
                              </Row>
                              <Row gutter={16}>
                                    <Col className="gutter-row" span={24}>
                                          <div className='d-flex' style={{ marginBottom: 16 }}>
                                                <Input placeholder={strings.email} value={email} onChange={onChange2} />
                                          </div>
                                    </Col>
                              </Row>
                        </Col>
                        <Col className="gutter-row" span={6}>
                              <div style={{ marginBottom: 16 }}>
                                    <div className="gutter-box">
                                          <div {...getRootProps()} className={imageUrl === '' ? "comanfile_upload d-flex" : "d-none"} >
                                                <input {...getInputProps()} />
                                                {isDragActive ? (
                                                      <p>Drop the files here ...</p>
                                                ) : (
                                                            <Icon type="cloud-upload" className="m-auto icon" />
                                                      )}
                                          </div>
                                          <img src={imageUrl} className={imageUrl === '' ? "d-none" : ""} alt="avatar" style={{ width: '100%', height: '4.5em' }} />
                                          <Icon type="delete" style={{ marginLeft: '2.5em' }} theme="twoTone" className={imageUrl === '' ? "d-none" : ""} onClick={() => { setimageUrl(''); setFile(''); }} />
                                    </div>
                              </div>
                        </Col>
                  </Row>
                  <Row gutter={16}>
                        <Col className="gutter-row" span={12}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Input placeholder={strings.vlp} value={vehicle_licenseplate} onChange={onChange5} />
                              </div>
                        </Col>
                        <Col className="gutter-row" span={12}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Select defaultValue={strings.ut} style={{ width: '-webkit-fill-available' }} onChange={onChange6}>
                                          <Option value="VP">VP</Option>
                                          <Option value="DIRECTOR">DIRECTOR</Option>
                                          <Option value="GERENTE">GERENTE</Option>
                                          <Option value="NORMAL"> NORMAL </Option>
                                          <Option value="DISABLED"> DISABLED </Option>
                                    </Select>
                              </div>
                        </Col>
                  </Row>
                  <Row className={user_type === 'VP' || user_type === 'DIRECTOR' || user_type === 'GERENTE' ? "" : "d-none"} gutter={16}>
                        <Col className="gutter-row" span={24}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Query query={FIND_VP_SLOT} variables={{ "category": user_type }} fetchPolicy={"no-cache"}>
                                          {({ loading, data }) => {
                                                console.log(data);
                                                const vp_slots = data.vp_slots || []
                                                return (
                                                      <Select defaultValue={strings.SLOT} style={{ width: '-webkit-fill-available' }} onChange={onSlot}>
                                                            {vp_slots.map(item => <Option key={item._id}>{item.slot_name}_{item.slot_no}</Option>)}
                                                      </Select>
                                                );
                                          }}
                                    </Query>
                              </div>
                        </Col>
                  </Row>
                  <Row gutter={16}>
                        <Col className="gutter-row" span={24}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Input.Password placeholder={strings.pw} value={password} onChange={onChange7} />
                              </div>
                        </Col>
                  </Row>
                  <Row gutter={16}>
                        <Col className="gutter-row" span={12}>
                              <div style={{ marginBottom: 16 }}>
                                    <Input placeholder={strings.vm} value={vehicle_model} onChange={onChange3} />
                              </div>
                        </Col>
                        <Col className="gutter-row" span={12}>
                              <div style={{ marginBottom: 16 }}>
                                    <Input placeholder={strings.vc} value={vehicle_color} onChange={onChange4} />
                              </div>
                        </Col>
                  </Row>
                  <Row gutter={16}>
                        <Col className="gutter-row" span={12}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Input placeholder={strings.employee_number} value={employeenumber} onChange={onChange8} />
                              </div>
                        </Col>
                        <Col className="gutter-row" span={12}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Input placeholder={strings.card_family} value={cardfamily} onChange={onChange9} />
                              </div>
                        </Col>
                        <Col className="gutter-row" span={24}>
                              <div className='d-flex' style={{ marginBottom: 16 }}>
                                    <Input placeholder={strings.card_number} value={cardnumber} onChange={onChange10} />
                              </div>
                        </Col>
                  </Row>
                  <Row gutter={16}>
                        <Col className="gutter-row" span={24}>
                              <div style={{ marginBottom: 16 }}>
                                    <LocaleProvider locale={fr_FR}>
                                    <RangePicker className="w-100" onChange={(date,dateStrings) => {setstart_date(dateStrings[0]);setend_date(dateStrings[1]);}} />
                                    </LocaleProvider>
                              </div>
                        </Col>
                  </Row>
                  <Row>
                        <Col>
                              <Button key="back" onClick={oncancel}> Cancel </Button>
                              <Button key="submit" className="float-right" type="primary" onClick={AddUser}> Add </Button>
                        </Col>
                  </Row>
            </div>
      );
};
