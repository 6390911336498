import gql from "graphql-tag";

export const ADD_ADMIN_REPORT = gql`
    mutation ADD_ADMINREPORT($admin_id: ID,$report_category: String,$file:Upload,$slot_no:String,$slot_name:String,$lang:String) {   
        admin_addRepot(admin_id:$admin_id, report_category:$report_category, file: $file,slot_no:$slot_no,slot_name:$slot_name,lang:$lang)
        {
            msg,
            status,
        }
    }
`;