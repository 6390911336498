
import React, { useCallback } from "react";
import { Layout, Icon, Avatar, Form, Input, Tooltip, Button, message, Typography, Row, Col, Card, Table } from 'antd';
import 'antd/dist/antd.css';
import '../../../scss/template.scss';
import '../../../scss/Dashboard.scss';
import TemplateSider from '../../Layout/TemplateSider';
import TemplateHeader from '../../Layout/TemplateHeader';
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
 import {Add_Penalty_Manage} from './Add_Penalty_Mange';
const { Content } = Layout;
const { Text } = Typography;

class Add_Penalty_Template extends React.Component {
    state = {
        collapsed: false,
    };

    onToggle = (val) => {
        console.log(val);
        this.setState({
            collapsed: val,
        });
    };

    render() {

        return (
            <Layout style={{ height: '100vh' }}>
                <TemplateSider update_collapsed={this.state.collapsed} />
                <Layout>
                    <TemplateHeader toogle={this.onToggle} />
                    <Content className="content">
                        <Add_Penalty_Manage />
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default Add_Penalty_Template;
