import gql from "graphql-tag";
export const GET_SETTINGS = gql`
{
    Settings
    {
        _id,
        key,
        value
    }
}
`;



export const UPDATE_SETTINGS = gql`
    mutation UPDATESETTINGS($key:String, $value:String, $lang: String) {   
        updateSettings(key: $key,value: $value,lang: $lang)
        {
            msg
            status         
        }
}`


