import gql from "graphql-tag";

export const GET_HOLIDAY = gql`
    query findslot($_id: ID,$lang:String) {
      holiday(_id: $_id,lang:$lang) 
      {
            _id
            holiday
            description
      }
    }
`;

export const ADD_HOLIDAY = gql`
    mutation ADDHOLIDAY($holiday:String,$description:String,$lang:String) {   
      addHoliday(holiday:$holiday,description:$description,lang:$lang)
        {
            msg,
            status,
        }
    }
`;

export const UPDATE_HOLIDAY = gql`
    mutation UPDATEHOLIDAY($_id:ID,$holiday:String,$description:String,$lang:String) {   
      updateHoliday(_id:$_id,holiday:$holiday,description:$description,lang:$lang)
        {
            msg,
            status,
        }
    }
`;

export const DELETE_HOLIDAY = gql`
    mutation DELETEHOLIDAY($_id: ID,$lang:String)  {
      deleteHoliday(_id:$_id,lang:$lang){
        msg,
        status         
    }
}`;