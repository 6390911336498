
import React, { useCallback } from "react";
import { Layout, Icon, Avatar, Form, Input, Tooltip, Button, message, Popconfirm, Typography, Row, Col, Card, Table, Modal, Tag, Upload } from 'antd';
import { Uploads } from './Uploads';
import { Update } from './Update';
import { BrowserRouter as Router, Route, Link, withRouter } from 'react-router-dom';
import { Alert } from '../../Layout/Files';
import 'antd/dist/antd.css';
import '../../../scss/template.scss';
import '../../../scss/Dashboard.scss';
import '../../../scss/User.scss';
import { GET_PENALTYDETAIL, DELETE_PENALTY, FIND_PENALTY } from '../../../graphql/PenaltyDetail';
import { client } from "../../../graphql/Client";
import { strings } from "../../Layout/Strings";
import { ens } from '../../Layout/TemplateHeader';


const { Content } = Layout;
const { Text } = Typography;
class PenalityDetail extends React.Component {
      constructor(props) {
            super(props);
            this.state = {
                  _id: '',
                  penality_up: false,
                  penalty_ud: false,
                  pictures: [],
                  user_name: '',
                  sure_name: '',
                  email: '',
                  vehicle_color: '',
                  vehicle_licenseplate: '',
                  vehicle_model: '',
                  user_type: '',
                  file: '',
                  dataSource: [],
                  data: {},
                  loading:1,
            };
      }

      componentDidMount() {
            this.fetch_penality_detail();
      }
      // fecth user
      fetch_penality_detail = () => {
            client.query({
                  query: GET_PENALTYDETAIL,
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  console.log(result.data.panality_detail);
                  this.setState({loading:false, penality_up: 0, penalty_ud: 0, dataSource: result.data.panality_detail });
            });
      }
      // update user
      updateDetail = async (data) => {
            console.log(data);
            Alert(data.updatePenaltyDetail);
            this.fetch_penality_detail();
      }
      // add user
      addPenalitydata = async (data) => {
            console.log(data);
            Alert(data.addPenaltyDetail);
            this.fetch_penality_detail();
      }
      // delete a record
      DeletePenalty = async (_id) => {
            console.log(_id);
            await client.mutate({
                  mutation: DELETE_PENALTY,
                  variables: { _id: _id, lang: ens},
            }).then(result => {
                  Alert(result.data.deletePenaltyDetail);
            });
            this.fetch_penality_detail();
      };
      // user data visible in update process
      findPenalty = async (_id) => {
            console.log(_id);
            await client.query({
                  query: FIND_PENALTY,
                  variables: { _id: _id, lang: ens },
                  fetchPolicy: 'no-cache',
            }).then(result => {
                  console.log(result.data);
                  this.setState({ data: result.data.panality_detail, penalty_ud: 1 });
            });
      }

      onTableChange = (pagination, filters, sorter) => {
            console.log(pagination, filters, sorter);
      }
      render() {

            const columns = [
                  {
                        title: <span>{strings.pl}</span>,
                        dataIndex: '',
                        key: 'penality_level',
                        width: 30,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="Penalty Level">{record.penality_level}</span>;
                        }
                  },
                  {
                        title: <span>{strings.du}</span>,
                        dataIndex: '',
                        key: 'duration',
                        width: 30,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="Duration">{record.duration}</span>;
                        }
                  },
                  {
                        title: <span>{strings.reson}</span>,
                        dataIndex: '',
                        key: 'cause',
                        width: 30,
                        align: 'center',
                        render: (text, record) => {
                              return <span title="Reson">{record.cause}</span>;
                        }
                  },
                  {
                        title: '...',
                        dataIndex: '...',
                        width: 5,
                        align: 'center',
                        render: (text, record) => {
                              if (this.state.dataSource.length >= 1) {
                                    return (
                                          <div>
                                                <Icon type="edit" tabIndex="-1" onClick={() => this.findPenalty(record._id)} />
                                                <Popconfirm title="Sure to delete?" onConfirm={() => this.DeletePenalty(record._id)}>
                                                      <Icon type="delete" />
                                                </Popconfirm>
                                          </div>
                                    );
                              }
                        }
                  },
            ];

            return (
                  <div>
                        <Modal
                              title={strings.upd}
                              style={{ top: 20 }}
                              visible={this.state.penalty_ud}
                              onCancel={() => this.setState({ penalty_ud: 0, data: '' })}
                              footer={[]} >
                              <Update updatepenalitydata={this.updateDetail} data={this.state.data} cancelmodel={() => { this.setState({ penalty_ud: 0 }) }} />
                        </Modal>

                        {/* add user in model */}

                        <Modal
                              title={strings.apd}
                              style={{ top: 20 }}
                              visible={this.state.penality_up}
                              onCancel={() => this.setState({ penality_up: 0, data: '' })}
                              footer={[]} >
                              <Uploads addpenalitydata={this.addPenalitydata} cancelmodel={(data) => { console.log(data); this.setState({ penality_up: 0, data: '' }); }} />
                        </Modal>

                        {/* add user icon button */}
                        <div className="user_add_position">
                              <Tooltip placement="left" title={"Penality Data"}>
                                    <Icon type="carry-out" className="user_add icon_size" onClick={() => { this.setState({ penality_up: 1 }) }} />
                              </Tooltip>
                        </div>
                        <div id="no-more-tables">
                              <Table
                                    pagination={{ simple: true }}
                                    dataSource={this.state.dataSource}
                                    columns={columns}
                                    bordered
                                    loading={this.state.loading}
                                    onChange={this.onTableChange}
                              />
                        </div>
                  </div>

            );
      }
}

export default withRouter(PenalityDetail);
